body {
    margin: 0;

    background-color: #2D73C0;
    color: white;
    font-family: Arial;
}

label {
    display: inline-block;

    line-height: 34px;
    padding: 5px;

    background-color: rgb(221, 222, 221);
    color: #222222
}

input {
    line-height: 35px;
    padding: 5px;

    border: 0px;
}

button:disabled {
    width: 60px;
    line-height: 25px;
    padding: 10px;

    border: 0px;
}

button {
    padding: 10px;
    line-height: 25px;

    border: 0px;

}

a {
    text-decoration: none;
    color: white;
}